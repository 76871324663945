<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Star Bewerbung</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="!userMadeApplication">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Künstlername">Künstlername</ion-label>
            <ion-input v-model="applicationName"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Instagram">Instagram Benutzername</ion-label>
            <ion-input v-model="applicationInstagram"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-item>
            <ion-label position="stacked" placeholder="Wikipedia">Wikipedia Link</ion-label>
            <ion-input v-model="applicationWikipedia"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <ion-item>
            <ion-label position="stacked" placeholder="Wikipedia">Vorwahl</ion-label>
            <ion-input v-model="applicationPhonePrefix"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="8">
          <ion-item>
            <ion-label position="stacked" placeholder="Wikipedia">Mobilnummer</ion-label>
            <ion-input v-model="applicationPhone"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="12">
          <ion-button shape="round" style="width: 95%; margin-left: 5%;" @click="submitStarApplication">Speichern</ion-button>
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-content :fullscreen="true" v-if="applicationAccepted && userMadeApplication">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12" style="height: 250px; width: 100%; position: relative; margin-bottom: 50px;">
          <img class="rounded-image imageCircleAccepted" src="http://via.placeholder.com/250x250.png?text=Accepted" alt="test" style="max-width: 100%;max-height: 100%;overflow: auto;margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;" />
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-content :fullscreen="true" v-if="applicationDenied && userMadeApplication">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12" style="height: 250px; width: 100%; position: relative; margin-bottom: 50px;">
          <img class="rounded-image imageCircleDenied" src="http://via.placeholder.com/250x250.png?text=Denied" alt="test" style="max-width: 100%;max-height: 100%;overflow: auto;margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;" />
        </ion-col>
      </ion-row>
    </ion-content>

    <ion-content :fullscreen="true" v-if="applicationInReview && userMadeApplication">
      <ion-row class="sideMargins topMargins">
        <ion-col size="12" style="height: 250px; width: 100%; position: relative; margin-bottom: 50px;">
          <img class="rounded-image imageCircle" src="http://via.placeholder.com/250x250.png?text=InReview" alt="test" style="max-width: 100%;max-height: 100%;overflow: auto;margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;" />
        </ion-col>
      </ion-row>
    </ion-content>

  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  toastController
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Stars',
  data () {
    return {
      userMadeApplication: false,
      applicationAccepted: false,
      applicationDenied: false,
      applicationInReview: false,
      applicationName: "",
      applicationInstagram: "",
      applicationWikipedia: "",
      applicationPhonePrefix: "+49",
      applicationPhone: "",
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonInput,
    IonLabel
  },
  created() {
    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){

      // check if user has made an application
      axios.get(process.env.VUE_APP_API_URL +'/star-application-status', {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            // check if user made application
            if (response.data["userMadeApplication"] !== "false"){
              this.userMadeApplication = true;

              // check for application status
              if (response.data["status"] == "accepted"){
                // show accepted application
                this.applicationAccepted = true;
              } else if (response.data["status"] == "denied") {
                // show denied application
                this.applicationDenied = true;
              } else {
                // show in review box
                this.applicationInReview = true;
              }
            }

          });

    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }
  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    submitStarApplication(){

        const data = {
          'name' : this.applicationName,
          'instagramUrl' : this.applicationInstagram,
          'wikipediaUrl' : this.applicationWikipedia,
          'phoneCountry' : this.applicationPhonePrefix,
          'phoneNumber' : parseInt(this.applicationPhone)
        };

        console.log(data);

        if (this.applicationName == "" || this.applicationInstagram == "" || this.applicationWikipedia == "" || this.applicationPhonePrefix == "" || this.applicationPhone == ""){
            this.openToast('Fülle alle Felder aus.', 5000);
        } else {

          axios.post(process.env.VUE_APP_API_URL + '/star_applications', data, {
            headers: {
              'Authorization': `Bearer ` + localStorage.userToken
            }
          })
          .then(() => {
              this.userMadeApplication = true;
              this.applicationInReview = true;
          });

        }

    }
  }
});

</script>

<style scoped>

.sideMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.topMargins {
  margin-top: 10%;
}

.imageCircle {
  border: 2px solid #1dc0c6;
}

.imageCircleDenied {
  border: 2px solid #c61d53;
}

.imageCircleAccepted {
  border: 2px solid #1dc620;
}

.rounded-image {
  border-radius: 50%;
}

</style>