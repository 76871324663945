
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  toastController
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Stars',
  data () {
    return {
      userMadeApplication: false,
      applicationAccepted: false,
      applicationDenied: false,
      applicationInReview: false,
      applicationName: "",
      applicationInstagram: "",
      applicationWikipedia: "",
      applicationPhonePrefix: "+49",
      applicationPhone: "",
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonInput,
    IonLabel
  },
  created() {
    // check if user is logged in
    if (localStorage.userId && localStorage.userToken){

      // check if user has made an application
      axios.get(process.env.VUE_APP_API_URL +'/star-application-status', {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            // check if user made application
            if (response.data["userMadeApplication"] !== "false"){
              this.userMadeApplication = true;

              // check for application status
              if (response.data["status"] == "accepted"){
                // show accepted application
                this.applicationAccepted = true;
              } else if (response.data["status"] == "denied") {
                // show denied application
                this.applicationDenied = true;
              } else {
                // show in review box
                this.applicationInReview = true;
              }
            }

          });

    } else {
      // push to main
      this.$router.push({ path: '/login' })
    }
  },
  methods: {
    async openToast(message: string, duration: number) {
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
            color: "danger"
          })
      return toast.present();
    },
    submitStarApplication(){

        const data = {
          'name' : this.applicationName,
          'instagramUrl' : this.applicationInstagram,
          'wikipediaUrl' : this.applicationWikipedia,
          'phoneCountry' : this.applicationPhonePrefix,
          'phoneNumber' : parseInt(this.applicationPhone)
        };

        console.log(data);

        if (this.applicationName == "" || this.applicationInstagram == "" || this.applicationWikipedia == "" || this.applicationPhonePrefix == "" || this.applicationPhone == ""){
            this.openToast('Fülle alle Felder aus.', 5000);
        } else {

          axios.post(process.env.VUE_APP_API_URL + '/star_applications', data, {
            headers: {
              'Authorization': `Bearer ` + localStorage.userToken
            }
          })
          .then(() => {
              this.userMadeApplication = true;
              this.applicationInReview = true;
          });

        }

    }
  }
});

